import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const RecentScholarlyImpactPage = () => (
  <Layout>
    <Seo title="Corporate Governance & Director Skills" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-16">
        <div class="format_text">
          <h1>Corporate Governance &amp; Director Skills</h1>
          <table style={{ marginTop: 0 }}>
            <tbody>
              <tr>
                <td>
                  <span
                    style={{
                      float: "left",
                      margin: "0 0.5em 0.5em 0.5em",
                      textAlign: "left",
                      display: "block",
                    }}
                  >
                    <StaticImage
                      src="../images/shutterstock_519167-300x199.jpeg"
                      alt="Pen on paper"
                    />
                  </span>
                  <p>
                    Larry has held numerous corporate directorships in
                    publicly-traded and privately-owned corporations engaged in
                    such diverse industries as dairy processing, financial
                    services, franchising and fast food (Custom Creamery, Orange
                    Julius and Crescott, Inc.– all NASDAQ), mortgage banking,
                    publishing, software and information technology, oil &amp;
                    gas (Comstock Resources, Inc., NYSE: CRK), and
                    others.&nbsp;He served as Chairman of the Committee of
                    Independent Directors in the sale of Orange Julius, Inc. to
                    International Dairy Queen, now owned by Warren Buffett’s{" "}
                    <a href="http://www.berkshirehathaway.com/">
                      Berkshire Hathaway, Inc
                    </a>
                    .
                  </p>
                  <p>
                    Selected other directorships include OZMA Corporation
                    (Chairman); First Midwest Mortgage Corporation (Chairman);
                    First Chesapeake Mortgage, Inc. (Vice Chairman); HomeLenders
                    of America, Inc. (Vice Chairman); AmeriMac Corporation (Vice
                    Chairman, sold to CrossLand Capital, at the time the largest
                    U.S. savings institution east of the Mississippi River);
                    IntelCap, Inc. (Chairman); Cogent Information Analytics,
                    Inc. (Chairman), and others.
                  </p>
                  <p>
                    Recognized by the{" "}
                    <a href="http://www.nacdonline.org/">
                      National Association of Corporate Directors
                    </a>{" "}
                    for his many contributions to corporate governance, he
                    served as President of the organization’s New York and
                    Metropolitan Baltimore/Washington, DC chapters.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h1 class="breakhere">Accounting and Compliance Audits</h1>
                  <p>
                    <span
                      style={{
                        float: "left",
                        margin: "0 0.5em 0.5em 0.5em",
                        textAlign: "left",
                        display: "block",
                      }}
                    >
                      <StaticImage
                        src="../images/shutterstock_5275660-Accounting-Compliance-Audits-300x212.jpeg"
                        alt="Glasses on computer"
                      />
                    </span>
                    He has served on several board audit committees of
                    SEC-reporting companies, taught accounting and finance at
                    several colleges and universities, and published extensively
                    on the topics of audit committee functions and corporate
                    finance. Early in his career, he routinely coordinated
                    mortgage industry policy regarding regulatory compliance
                    audits (HUD, FNMA, FHLMC, etc) and attended meetings of the
                    Financial Accounting Standards Board’s (“FASB”) Emerging
                    Issues Task Force while employed at the{" "}
                    <a href="http://www.mbaa.org/default.htm">
                      Mortgage Bankers Association of America
                    </a>
                    .&nbsp;(While completing all coursework necessary to sit for
                    the CPA exam), Larry’s move to New York to work as an
                    investment banker at Donaldson, Lufkin &amp; Jenrette
                    precluded his sitting for the CPA exam.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h1>Change Agent</h1>
                  <p>
                    <span
                      style={{
                        float: "left",
                        margin: "0 0.5em 0.5em 0.5em",
                        textAlign: "left",
                        display: "block",
                      }}
                    >
                      <StaticImage
                        src="../images/shutterstock_1097390-Change-Agent1-300x200.jpeg"
                        alt="DaVinci's illustration of man"
                      />
                    </span>
                    Some situations just call for dramatic change. Very often
                    the perceived unrealized value is recognized by an outsider
                    such as Carl Icahn (
                    <a href="http://www.icahnreport.com/">Icahn Report</a>) who
                    finds a company where he believes change represents value.
                    Typically this is a company that has lost credibility among
                    shareholders, is doing a poor job of communicating
                    enterprise value to Wall Street, or for many reasons is just
                    a lackluster performer.
                  </p>
                  <p>
                    A serial entrepreneur, Larry is responsible for over a dozen
                    start-ups which have resulted in the creation of hundreds of
                    new jobs. He is a founding director of Comstock Tunnel &amp;
                    Drainage Co. (now Comstock Resources, Inc. NYSE: CRK),
                    beginning with a negative net worth of $32,000 (in 1985).
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h1 class="breakhere">Leadership</h1>
                  <p>
                    <span
                      style={{
                        float: "left",
                        margin: "0 0.5em 0.5em 0.5em",
                        textAlign: "left",
                        display: "block",
                      }}
                    >
                      <StaticImage
                        src="../images/shutterstock_59318719-300x200.jpeg"
                        alt="Lightbulb"
                      />
                    </span>
                    He demonstrates a lifelong pattern of leadership: class
                    president and service-club president in high school, student
                    government president in College, energy law association
                    president during law school. He has been Chairman, Vice
                    Chairman or CEO of about a dozen corporate entities. He has
                    demonstrated leadership by serving as president of the
                    Dallas Internet Society and as president of the New York and
                    Metropolitan Baltimore/Washington, DC chapters of the{" "}
                    <a href="http://www.nacdonline.org/">
                      National Association of Corporate Directors
                    </a>
                    .
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h1>Legal and Securities Regulatory Compliance</h1>
                  <p>
                    <span
                      style={{
                        float: "left",
                        margin: "0 0.5em 0.5em 0.5em",
                        textAlign: "left",
                        display: "block",
                      }}
                    >
                      <StaticImage
                        src="../images/shutterstock_1658757-Legal2-300x199.jpeg"
                        alt="Books"
                      />
                    </span>
                    Larry’s first drafting of an offering prospectus was many
                    years ago, when he served as Chairman of OZMA Corporation, a
                    Washington, DC-based financial services entity operating in
                    the mortgage banking industry.&nbsp;During the following
                    years, he has spent thousands of hours involved in the
                    drafting of various corporate, legal, and securities
                    disclosure documents for many of the various entities he has
                    been associated with or for investment banking clients.
                  </p>
                  <p>
                    Law school afforded him additional skills in the areas of
                    agency and partnership, corporations, mergers and
                    acquisitions, oil and gas, and securities regulations. He is
                    author of numerous articles on the topics of law, finance
                    and corporate governance.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h1 class="breakhere">Securities Analysis</h1>
                  <p>
                    <span
                      style={{
                        float: "left",
                        margin: "0 0.5em 0.5em 0.5em",
                        textAlign: "left",
                        display: "block",
                      }}
                    >
                      <StaticImage
                        src="../images/shutterstock_14993704-Securities-Analysis-300x214.jpeg"
                        alt="Pen with statistics"
                      />
                    </span>
                    Having trained in business school as a securities analyst,
                    he subsequently worked as an investment banker at Donaldson,
                    Lufkin &amp; Jenrette in New York.&nbsp;As a result, he
                    understands investment valuation and how various functions
                    in the capital formation process come together to allow for
                    the efficient raising of necessary investment capital.
                  </p>
                  <p>
                    Early in his career, having completed all course
                    requirements necessary for the CPA, he worked at Disclosure,
                    Inc. as a financial analyst under a contract with the U.S.
                    Securities and Exchange Commission, conducting financial
                    analysis and abstracting and indexing certain financial
                    statements and other disclosure items (Forms 10-K, 8-K,
                    10-Q, etc.) filed with the{" "}
                    <a href="http://www.sec.gov/">
                      Securities and Exchange Commission
                    </a>
                    .
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h1>Strategic Planning</h1>
                  <p>
                    <span
                      style={{
                        float: "left",
                        margin: "0 0.5em 0.5em 0.5em",
                        textAlign: "left",
                        display: "block",
                      }}
                    >
                      <StaticImage
                        src="../images/shutterstock_5752699-Strategic-Planning-300x200.jpeg"
                        alt="Puzzle pieces"
                      />
                    </span>
                    Maybe the most difficult talent to spot in director
                    candidates (you can’t tell by looking) is the eclectic knack
                    for “that vision thing”.&nbsp;Experience gained from a
                    strategic planning process may prove beneficial toward
                    assisting your board in development of a culture of
                    strategic planning where decisions are made within a
                    framework of constantly challenging the fundamental
                    assumptions about where the enterprise intends to be five
                    years from now and how you intend to get
                    there.&nbsp;Strategic planning seems to be so fundamental to
                    the success of any enterprise and yet universally
                    acknowledged to receive inadequate attention by many boards.
                  </p>
                  <p>
                    It was many years ago that he first became fascinated by the
                    wisdom of <a href="http://www.ti.com/">Texas Instruments</a>{" "}
                    to have a standing Strategic Planning committee of the
                    board. Texas Instruments at that time was very homogeneous,
                    with almost every member having an engineering
                    background.&nbsp;The TI Strategic Planning Committee was
                    chaired by attorney Bryan Smith, Chancellor of the Texas
                    University System and veteran director of numerous major
                    boards such as Merrill Lynch and Texas Instruments, just to
                    name a few. Conversations with Bryan Smith motivated me to
                    write{" "}
                    <em>
                      FOCUS ON THE FUTURE: THE STRATEGIC PLANNING COMMITTEE,{" "}
                    </em>
                    published by the National Association of Corporate
                    Directors, Washington, Director’s Monthly (
                    <a href="http://www.nacdonline.org/">NACD</a>
                    ).
                  </p>
                  <p>
                    It appears universally agreed upon that nothing is more
                    fundamental to the success of any enterprise than having a
                    clearly communicated shared vision of where the corporation
                    is headed, before there can be any chance of achieving these
                    goals. Yet most boards have no “system” in place to nurture
                    continuous thinking and decision making over-time or provide
                    a systematized feedback loop as to progress against
                    strategic goals (indeed to systematically assess whether the
                    strategic assumptions remain sound, or need modification).
                    This paradox is commonly restated by the axiom “if you don’t
                    know where you are going, almost any road will take you
                    there”. My advocating the presence of a standing strategic
                    planning committee at the board level many years ago has
                    obviously been a minority view since there has subsequently
                    been an astonishing absence of such committees to be found
                    among the corporate landscape.
                  </p>
                  <p>
                    However, “I’m going to stick with my view that in the
                    long-term, absolutely nothing is more important than
                    visionary thinking”. Constantly questioning assumptions
                    about where we think we’re headed five years from now seems
                    a fundamental foundation for ongoing decision making.
                    Getting lost in the weeds of regulatory disclosure and
                    making sure the accounting is correct does not go to the
                    heart of anticipating the future needs of your customer. Nor
                    does near-term tunnel vision provide adequate resources so
                    that you profitably meet the needs of your customer before
                    they are met by your competition.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h1>Value and Job Creation</h1>
                  <p>
                    <span
                      style={{
                        float: "left",
                        margin: "0 0.5em 0.5em 0.5em",
                        textAlign: "left",
                        display: "block",
                      }}
                    >
                      <StaticImage
                        src="../images/shutterstock_15463591-Value-Job-Creation-300x209.jpeg"
                        alt="DaVinci's flying machine"
                      />
                    </span>
                    One of the best indicators of the likelihood of future
                    success is the extent to which someone has accomplished
                    their goals in the past. This may often be a sub set of “the
                    vision thing”. Larry remains most proud of being able to
                    create hundreds of jobs through his entrepreneurship. Small
                    business is the primary economic engine for job creation. He
                    is credited with perhaps a dozen start-ups.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h1 class="breakhere">Workout and Turnaround Expertise</h1>
                  <p>
                    <span
                      style={{
                        float: "left",
                        margin: "0 0.5em 0.5em 0.5em",
                        textAlign: "left",
                        display: "block",
                      }}
                    >
                      <StaticImage
                        src="../images/shutterstock_10603069-Bankruptcy1-300x200.jpeg"
                        alt="Foreclosure notice"
                      />
                    </span>
                    Mr. Trautman has legal training in bankruptcy and corporate
                    reorganization. Even for a company that shows no indication
                    of ever being in a situation of needing bankruptcy advice,
                    these skills in a board member may prove valuable in an
                    instant where your company is acquiring assets or stock in a
                    turnaround or bankruptcy setting. The lessons learned from
                    these experiences might bring valuable insight to board
                    deliberations.&nbsp;Should the unthinkable happen and
                    bankruptcy considerations face you (or a decision to not
                    seek bankruptcy protection), these experiences are valuable
                    to any board.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecentScholarlyImpactPage
